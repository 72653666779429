import React from 'react'
import './MySkill.scss'

function MySkill({ title, icon, skill }) {
  return (
    <section className='skill__page'>

      <Heading key={title} title={title} icon={icon} />
      {/* {JSON.stringify(skill)} */}


      {
        Object.keys(skill).map((keyName, keyIndex) =>
          <VisualData
            key={keyIndex}
            name={keyName}
            value={skill[keyName]}
          />)
      }



    </section>
  )
}



const Heading = ({ title, icon }) => {
  return (
    <div className='skill__page__heading'>
      <span>
        <i className={`bi ${icon}`}></i>
      </span>
      <div>{title}</div>
    </div>
  )
}


const VisualData = ({ name, value }) => {
  return (
    <div className='skill__page__skill' >
      <div className="value">
        <span>{name}</span>
        <span className='inside'>{value}</span>
      </div>
      <div className="visual">
        <div style={{ width: `${value}` }} className="visual__dark"></div>
      </div>
    </div>
  )
}



export default MySkill
